import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useStyles} from "../../theme/theme";
import {TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {deleteData, getData} from "../../shared/doLogin";
import UserContext from "../../contexts/UserContext";
import {AddCircle, ArtTrack, Delete, Edit} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {Agency, AgencyListResponse} from "../../models/AgencyData";
import {Link, RouteComponentProps} from "@reach/router";
import {ClientEvent} from "clientevent";
import {Events} from "../../models/Events";
import {ConfirmationDialog} from "../../shared/ConfirmationDialog";

export function AgenciesList(props: RouteComponentProps) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [agencies, setAgencies] = useState<Agency[]>([]);
  const [selectedAgency, setSelectedAgency] = useState<string>("");
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    getAgencies();
  }, []);

  const getAgencies = () => {
    getData("/agencies", {}, user ? user.token : "")
      .then((response: AgencyListResponse) => {
        if (response.status === "Success") {
          setAgencies(response.data.agencies);
        }
      });
  };

  const deleteAgency = (confirmation: boolean) => {
    if (!!confirmation)
      deleteData("/agency", {id: selectedAgency}, user ? user.token : "")
        .then((response) => {
          if (response.status === "Success") {
            let newAgencies = agencies.filter(a => a.id != selectedAgency);
            setAgencies(newAgencies);
          }
          setConfirmDeleteDialogOpen(false);
        });
    setConfirmDeleteDialogOpen(false);
  };

  return (
    <TableContainer component={Paper}>
      <div style={{
        marginTop: 10,
        textAlign: "right",
        marginRight: 30
      }}>
        <Button
          style={{borderColor: "black", borderWidth: 1, borderRadius: 5}}
          onClick={() => ClientEvent.emit(Events.NAVIGATE, "agencies/add")}
        >
          <AddCircle/>
          Add
        </Button>
      </div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Agency</TableCell>
            <TableCell align="right">POC</TableCell>
            <TableCell align="right">Address</TableCell>
            <TableCell align="right">Phone / Email</TableCell>
            <TableCell align="right">Playgrounds</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agencies.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">{row.name}</TableCell>
              <TableCell align="right">{row.poc}</TableCell>
              <TableCell align="right">{row.address1}</TableCell>
              <TableCell align="right">{row.phone}</TableCell>
              <TableCell align="right">{row.playgrounds || 0}</TableCell>
              <TableCell align="right">
                <>
                  <Button onClick={() => ClientEvent.emit(Events.NAVIGATE, `agencies/edit/${row.id}`)}
                          title={"Edit"}
                  >
                    <Edit/>
                  </Button>
                  <Button onClick={() => {
                    setSelectedAgency(row.id);
                    setConfirmDeleteDialogOpen(true);
                  }}
                          title={"delete"}
                  >
                    <Delete/>
                  </Button>
                  <Button onClick={() => ClientEvent.emit(Events.NAVIGATE, `agencies/${row.id}/playgrounds`)}
                          title={"Web Preview"}
                  >
                    <ArtTrack/>
                  </Button>
                </>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmationDialog
        open={confirmDeleteDialogOpen}
        handleClose={(confirmation: boolean) => deleteAgency(confirmation)}
        confirmationDescription={"Are you sure you want to delete agency?"}
      />
    </TableContainer>
  )
}