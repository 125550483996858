import React, {useEffect} from 'react';
import {ClientEvent} from "clientevent";
import {Events} from "../models/Events";
import CircularProgress from "@material-ui/core/CircularProgress";
import {RouteComponentProps} from "@reach/router";

export default function Logout(props: RouteComponentProps) {
  // const user = useContext(UserContext);

  useEffect(() => {
    setTimeout(() => ClientEvent.emit(Events.DELETE_USER), 1000);
  }, []);

  return <CircularProgress/>
}