import {RouteComponentProps} from "@reach/router";
import React, {useContext} from "react";
import {DragAndDropUploadZone} from "../../shared/DragAndDropUploadZone";
import {uploadFile, uploadFileRaw} from "../../shared/doLogin";
import UserContext from "../../contexts/UserContext";

interface Props extends RouteComponentProps {

}

export function Home(props: Props) {
  const user = useContext(UserContext);

  const uploadIt = async (file: File) => {
    const data = new FormData();
    data.append("file", file);
    return uploadFileRaw("photo/save", data, user && user.token || "")
  }

  return (
    <div style={{display: "flex", margin: 10, justifyContent: "flex-end"}}>
      <div style={{width: 350, height: 200, outline: "grey 1px solid"}}>
        <DragAndDropUploadZone uploadFile={uploadIt}>
          <span>Failed files upload zone. Drag and drop files here that failed the device upload.</span>
        </DragAndDropUploadZone>
      </div>

    </div>
  )
}