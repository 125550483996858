import React, {useState} from 'react';
import {useStyles} from '../theme/theme';
import {Button, Card, CardActions, CardContent, CardHeader, TextField} from "@material-ui/core";
import Logo from '../assets/img/Logo.png';
import {ClientEvent} from "clientevent";
import {Events} from "../models/Events";
import {postData} from "../shared/doLogin";
import {AuthStoreTokenData, doLoginResponse} from "../models/UserDetail";
import {RouteComponentProps} from "@reach/router";

interface LoginResponse extends doLoginResponse {
  data: AuthStoreTokenData;
}

export default function LoginPage(props: RouteComponentProps) {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const processLogin = () => {
    postData("/login", {username, password})
      .then((response: LoginResponse) => {
        // console.log(response);
        if (response.status === "success") {
          ClientEvent.emit(Events.SET_USER, response.data);
        }
      });
  }

  return (
    <header className="App-header">
      <Card className={classes.root} style={pageStyle.card}>
        <CardHeader>
        </CardHeader>
        <CardContent>
          <div style={pageStyle.fieldRow}>
            <img src={Logo} alt={"Play-by-play Logo"}/>
          </div>
          <form>
            <div style={pageStyle.fieldRow}>
              <TextField id="login-username"
                         label="Username"
                         variant="outlined"
                         value={username}
                         autoComplete={"username"}
                         onChange={(evt) => setUsername(evt.target.value)}/>
            </div>
            <div style={pageStyle.fieldRow}>
              <TextField id="login-password"
                         label="Password"
                         variant="outlined"
                         type={"password"}
                         value={password}
                         autoComplete={"current-password"}
                         onChange={(evt) => setPassword(evt.target.value)}/>
            </div>
            <div style={pageStyle.buttonRow}>
              <Button variant="contained" color="primary" style={{fontWeight: "bold"}} onClick={() => {
                processLogin();
                // ClientEvent.emit(Events.SET_USER, username);
              }}>
                Login
              </Button>
            </div>
          </form>
        </CardContent>
        <CardActions>
          <Button size="small">
            Forgot
          </Button>
        </CardActions>
      </Card>
    </header>
  );
}
const pageStyle = {
  card: {
    minWidth: 300,
    width: "25%"
  },
  fieldRow: {
    marginBottom: 20
  },
  buttonRow: {
    marginBottom: 5
  }
}