import * as React from "react";
import {useEffect, useState} from "react";
import 'react-datepicker/dist/react-datepicker.css';
import "./css/genericForm.css";
import {Button} from "@material-ui/core";
import {Cancel, Check, Edit} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";

interface Props {
  value: any;
  confirm: (newVal: any) => void;
  label?: string;
  enableEdit: boolean;
  width?: number;
  justifyContent?: "start" | "center" | "end";
}

export function InlineEdit(props: Props) {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [newVal, setNewVal] = useState<string>(props.value);
  const justifyContent = props.justifyContent ? props.justifyContent : "normal";

  return (
    <div style={{display: "flex", margin: 5, justifyContent}}>
      <div>
        {
          !editMode ?
            <div>
              {props.value}
            </div>
            :
            <TextField
              id="filled-basic" label={props.label || ""}
              style={{width: props.width || 300, height: "100%", color: "white"}}
              variant="filled"
              multiline
              value={newVal}
              onChange={(e) => setNewVal(e.target.value)}
            />
        }
      </div>
      {props.enableEdit && (
        <>
          {
            !editMode ?
              <Button data-testid={'edit-button'} style={{width: 10, height: 20}} onClick={() => setEditMode(true)}>
                <Edit fontSize={"small"} style={{width: 15}}/>
              </Button> :
              <div style={{display: "flex"}}>
                <Button size={"small"} data-testid={'confirm-button'} style={{width: 10, height: 20}} onClick={() => {
                  props.confirm(newVal);
                  setEditMode(false);
                }}>
                  <Check style={{width: 15}}/>
                </Button>
                <Button data-testid={'cancel-button'} style={{width: 10, height: 20}} onClick={() => {
                  setEditMode(false);
                  setNewVal(props.value);
                }}>
                  <Cancel style={{width: 15}}/>
                </Button>
              </div>
          }
        </>
      )
      }
    </div>
  )
}