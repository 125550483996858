import * as React from "react";
import {useContext, useRef, useState} from "react";
import {useStyles} from "../../../theme/theme";
import {TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import UserContext from "../../../contexts/UserContext";

import {
  FlatInspectionAssetFinding,
  InspectionCondition, InspectionData,
  InspectionEditData,
  InspectionReportData
} from "../../../models/InspectionData";
import {formatDate} from "../../../shared/utils";
import {useReactToPrint} from "react-to-print";
import {RouteComponentProps} from "@reach/router";
import Button from "@material-ui/core/Button";
import {ClientEvent} from "clientevent";
import {Events} from "../../../models/Events";
import {InspectionHeader} from "../Header";
import {MediaImage} from "../../../shared/MediaImage";
import {InlineEdit} from "../../../shared/InlineEdit";
import {putData} from "../../../shared/doLogin";

interface Props extends RouteComponentProps {
  inspection: InspectionReportData;
}

export function QuickPreview(props: Props) {
  const classes = useStyles();
  const user = useContext(UserContext);
  // const {inspection} = props;
  const [inspection, setInspection] = useState<InspectionReportData>(props.inspection);
  const [showPencils, setShowPencils] = useState<boolean>(true);

  const previewRef = useRef(null);
  // @ts-ignore
  const handlePrint = useReactToPrint({
    content: () => previewRef.current,
  });

  function updateInspectionCondition(newItem: InspectionCondition) {
    let newConditions = inspection.inspection_conditions.map(item => {
      if (item.id === newItem.id) return {...newItem};
      return item;
    });
    setInspection({...inspection, inspection_conditions: newConditions});
    putData("/inspection/condition", newItem, user ? user.token : "")
      .then((response: any) => {
        console.log("response: ", response);
      });
  };

  function updateInspectionData(newInspection: InspectionEditData) {
    let newInspectionData: InspectionData = {
      ...inspection.inspection,
      upper_notes: newInspection.upper_notes
    }

    putData("/inspection", newInspection, user ? user.token : "")
      .then((response: any) => {
        console.log("response: ", response);
        setInspection({...inspection, inspection: newInspectionData})
      });
  }

  const allFindings: FlatInspectionAssetFinding[] = []
  inspection.asset_data.forEach((asset) => {
    asset.findings.forEach(finding => {
      const obj = {...finding, ...asset};
      delete obj['findings'];
      allFindings.push(obj);
    });

  });
  const inpection_type = inspection.types.find(type => inspection.inspection.inspection_type === type.id);
  const findings: FlatInspectionAssetFinding[] = allFindings.filter(asset => asset.compliant === 2);
  return (
    <>
      <div style={{position: "absolute", marginTop: 10}}>
        <Button variant="contained"
                onClick={() => ClientEvent.emit(Events.NAVIGATE, `/inspections/list`)}
        >&lt; Go back</Button>
        <Button variant="contained"
                onClick={() => {
                  setShowPencils(false);
                  setTimeout(() => handlePrint && handlePrint(), 500)
                }}
        >Print this out</Button>
      </div>
      <TableContainer component={Paper} ref={previewRef} style={{textAlign: "center"}}>
        <InspectionHeader/>
        <InlineEdit enableEdit={showPencils} width={500} justifyContent={"center"} value={inspection.inspection.upper_notes || ""}
                    confirm={(newVal: string) => {
                      updateInspectionData({id: inspection.inspection.inspection_id, upper_notes: newVal});
                    }}/>
        <h4>Inspection of:</h4>
        <Table style={{width: "80%", marginLeft: "auto", marginRight: "auto"}} aria-label="simple table dense"
               size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Site Name</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="right">Performed By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{inspection.inspection.playground_name}</TableCell>
              <TableCell align="left">{formatDate(inspection.inspection.inspection_dated)}</TableCell>
              <TableCell align="left">{inpection_type && inpection_type.description || ""}</TableCell>
              <TableCell align="right">{inspection.inspection.inspected_by}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h4>Results:</h4>
        <Table style={{width: "80%", marginLeft: "auto", marginRight: "auto"}} aria-label="simple table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Asset</TableCell>
              <TableCell align="left">Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inspection.inspection_conditions.map((row, index) => {
              return <TableRow key={index} style={{pageBreakInside: "avoid", pageBreakAfter: "auto"}}>
                <TableCell align="left" style={{textAlign: "left", width: 350}}>
                  <MediaImage media_id={row.pre}
                              taken_at={row.preAt}
                              height={300}
                  />
                </TableCell>
                <TableCell align="left" style={{textAlign: "left", verticalAlign: "middle"}}>
                  <InlineEdit enableEdit={showPencils} value={row.preNotes || ""} confirm={(newVal: string) => {
                    updateInspectionCondition({...row, preNotes: newVal});
                  }}/>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}