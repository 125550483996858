import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {RouteComponentProps} from "@reach/router";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import {ClientEvent} from "clientevent";
import UserContext from "../contexts/UserContext";
import {getData, postData} from "./doLogin";
import {Events} from "../models/Events";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextField from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Icon from '@material-ui/core/Icon/Icon';
import "./css/genericForm.css";

interface AdditionalFormDataInterface {
  [key: string]: string | number;
}

export interface GenericFormRow<T> {
  type: "number" | "string" | "multi-line" | "date",
  fieldName: keyof T,
  description: string,
  hidden?: boolean
}

export interface routeReplaceKeyValue {
  find: string,
  replace: string
}

interface Props<T> extends RouteComponentProps {
  rows: GenericFormRow<T>[],
  postEndpoint: string,
  fetchEndPoint?: string,
  navTo: string,
  fetchEndpointDataValues?: routeReplaceKeyValue[],
  formDataRouteParams?: (keyof T)[],
  navToReplaceValues?: routeReplaceKeyValue[]
}

export function GenericForm<T>(props: Props<T>) {
  const user = useContext(UserContext);
  const [formData, setFormData] = useState<T>({} as T);
  const [navTo, setNavTo] = useState<string>(props.navTo);

  useEffect(() => {
    let newFormData = {} as T;
    // @ts-ignore
    props.rows.forEach(r => newFormData[r.fieldName] = getDefaultValue(r));
    setFormData(newFormData);
    buildRoutes();
    fetchData();
  }, []);

  function getDefaultValue(r: GenericFormRow<T>) {
    switch (r.type) {
      case "number":
        return 0;
      case "date":
        return Date.now();
      default:
        return "";
    }
  }

  function buildRoutes() {
    let newNaveTo = navTo;
    // @ts-ignore
    props.navToReplaceValues && props.navToReplaceValues.forEach(p => newNaveTo = newNaveTo.replace(p.find, props[p.replace]));
    setNavTo(newNaveTo);
  }

  function fetchData() {
    let data = {};
    // @ts-ignore
    props.fetchEndpointDataValues && props.fetchEndpointDataValues.forEach(p => data[p.find] = props[p.replace]);
    if (!!props.fetchEndPoint) getData(props.fetchEndPoint, data).then((response: any) => {
      if (!!response) {
        convertToFullDateTime(response.data);
        setFormData(response.data);
      }
    });
  }

  function convertToFullDateTime(data: T) {
    props.rows.forEach((r) => {
      if (r.type === "date") {
        //@ts-ignore
        data[r.fieldName] = data[r.fieldName] * 1000;
      }
    });
  }

  function convertToCondencedDateTime(data: T) {
    props.rows.forEach((r) => {
      if (r.type === "date") {
        //@ts-ignore
        data[r.fieldName] = data[r.fieldName] / 1000;
      }
    });
  }

  function updateForm(field: keyof T, val: any) {
    if (typeof formData[field] === "number") {
      if (!isNaN(val) && val !== "") setFormData({...formData, [field]: parseFloat(val)});
    } else setFormData({...formData, [field]: val});
  };

  const onSave = () => {
    let a: T = {...formData};
    // @ts-ignore
    props.formDataRouteParams && props.formDataRouteParams.forEach((param) => a[param] = props[param]);
    convertToCondencedDateTime(a);

    postData(props.postEndpoint, a, user ? user.token : "")
      .then((response: any) => {
        console.log("response: ", response);
        ClientEvent.emit(Events.NAVIGATE, navTo);
      });
  };

  function getInputField(row: GenericFormRow<T>, index: number) {
    switch (row.type) {
      case "date":
        return (<div style={{width: '100%', maxWidth: '100%'}} className={"datePicker"} key={index}>
          <DatePicker
            className={"datePicker"}
            fullWidth={true}
            style={{width: '100%', maxWidth: '100%'}}
            id={`date-${index}`}
            dateFormat={"MM/dd/yyy"}
            scrollableYearDropdown={true}
            showYearDropdown={true}
            scrollableMonthYearDropdown={true}
            // @ts-ignore
            selected={formData[row.fieldName]}
            onChange={(date: Date, e: React.ChangeEvent<HTMLInputElement>) => {
              updateForm(row.fieldName, date ? date.getTime() : undefined);
            }}
            textFieldStyle={{width: '100%'}}
            customInput={
              <TextField
                label={row.fieldName}
                InputLabelProps={{shrink: true}}
                InputProps={{
                  placeholder: 'date',
                  endAdornment: adornment,
                }}
                style={{width: '100%'}}
              />
            }
          />
        </div>);
      case "multi-line":
      case "string":
      case "number":
      default:
        return (
          <FormControl fullWidth data-testid={`form-row-${index}`} key={index}>
            <InputLabel htmlFor="my-input">{row.fieldName}</InputLabel>
            <Input data-testid={`form-row-input-${index}`} multiline={row.type === "multi-line"} rows={5}
                   value={formData[row.fieldName] ? formData[row.fieldName] : ""}
                   id={`input-${index}`} aria-describedby="my-helper-text"
                   onChange={(e) => updateForm(row.fieldName, e.target.value)}/>
            <FormHelperText>{row.description}</FormHelperText>
          </FormControl>
        );
    }
  }

  const adornment = (
    <InputAdornment position="end">
      <Icon className="fas fa-calendar-alt" style={{fontSize: 16}}/>
    </InputAdornment>
  );

  return (
    <TableContainer component={Paper}>
      <div style={{width: "50%", minWidth: 200, marginRight: "auto", marginLeft: "auto"}}>
        {props.rows.filter(r => !r.hidden).map((row, index) => {
          return getInputField(row, index);
        })}
        <div style={{
          marginTop: 50,
          width: 300,
          display: "flex",
          justifyContent: "space-around",
          marginRight: "auto",
          marginLeft: "auto"
        }}>
          <Button color={"primary"} data-testid={'save-button'} variant={"contained"} onClick={onSave}>Save</Button>
          <Button variant={"contained"} data-testid={'cancel-button'}
                  onClick={() => ClientEvent.emit(Events.NAVIGATE, navTo)}>Cancel</Button>
        </div>
      </div>
    </TableContainer>
  )
}