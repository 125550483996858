import {useCallback, useState} from 'react'

export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick(tick => tick + 1);
  }, [])
  return update;
}

export function formatDate(date: Date | null, defaultValue?: string): string {
  if (!date) return defaultValue || "";
  return [
    `${zLen(date.getMonth() + 1, 2)}-${zLen(date.getDate(), 2)}-${date.getFullYear()}`,
    `${zLen(date.getHours() + 1, 2)}:${zLen(date.getMinutes(), 2)}`
  ].join(" ");
}

export function zLen(str: string | number | null, len: number): string {
  if (str === null) return "";
  if (String(str).length < len) {
    return zLen(`0${str}`, len);
  } else {
    return String(str);
  }
}

export function formatRiskLevel(value: number | null) {
  switch (value) {
    case 1:
      return "High";
    case 2:
      return "Medium";
    case 3:
      return "Low";
    default:
      return "";
  }
}

export function toDate(value: number){
  var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCSeconds(value);
  return d;
}
