import React, {useEffect} from 'react';
import {ClientEvent} from "clientevent";
import {Events} from "../models/Events";
import {postData} from "../shared/doLogin";
import {doLoginResponse} from "../models/UserDetail";
import CircularProgress from "@material-ui/core/CircularProgress";

interface LoginResponse extends doLoginResponse {
  data: LoginResponse;
}

interface Props {
  token: string;
}

export default function Loading(props: Props) {

  const processLogin = () => {
    postData("/reauth", {}, props.token)
      .then((response: LoginResponse) => {
        if (response.status === "success") {
          ClientEvent.emit(Events.SET_USER, response.data);
        } else {
          ClientEvent.emit(Events.DELETE_USER, response.message);
        }
      });
  };

  useEffect(() => {
    setTimeout(() => processLogin(), 1000);
  },[]);

  return <CircularProgress/>
}