import * as React from "react";
import 'react-datepicker/dist/react-datepicker.css';
import "../css/genericForm.css";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {ChangeEvent} from "react";
import {Grid} from "@material-ui/core";

interface Props {
  options: string[],
  selected: string[],
  title: string,
  updateSelectedOptions: (selected: string[]) => void
}

export function FilterPicker(props: Props) {
  return (
    <div style={{display: "flex"}}>
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid container item xs={4} spacing={2}>
          <h3 style={{marginRight: 10, fontSize: 20, height: 20}}>{props.title}</h3>
        </Grid>
        <Grid container item xs={8} spacing={2}>
          <Autocomplete
            fullWidth={true}
            multiple
            size="small"
            options={props.options}
            value={props.selected}
            onChange={(e: ChangeEvent<{}>, value: string[]) => {
              props.updateSelectedOptions(value);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="standard" placeholder="Favorites"/>
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}