import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useStyles} from "../../theme/theme";
import {getData} from "../../shared/doLogin";
import UserContext from "../../contexts/UserContext";

import {FlatInspectionAssetFinding, InspectionDataResponse, InspectionReportData} from "../../models/InspectionData";
import {RouteComponentProps} from "@reach/router";
import {HFPreview} from "./HF/HFPreview";
import {QuickPreview} from "./Quick/QuickPreview";

interface Props extends RouteComponentProps {
  inspection_id?: string;
}

export function InspectionPreview(props: Props) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [inspection, setInspection] = useState<InspectionReportData | undefined>();
  // @ts-ignore
  useEffect(() => {
    getInspections();
  }, []);

  const getInspections = () => {
    getData(`/inspection/${props.inspection_id}`, {}, user ? user.token : "")
      .then((response: InspectionDataResponse) => {
        if (response.status === "Success") {
          response.data.inspection.inspection_dated = new Date(response.data.inspection.inspection_dated);
          if (response.data.inspection.inspection_completed) {
            response.data.inspection.inspection_completed = new Date(response.data.inspection.inspection_completed);
          }
          response.data.asset_data = response.data.asset_data.map(card => {
            card.findings.map(
              row => {
                if (row.discovered_date) {
                  row.discovered_date = new Date(row.discovered_date);
                }
                if (row.resolve_by_date) {
                  row.resolve_by_date = new Date(row.resolve_by_date);
                }
                if (row.resolved_date) {
                  row.resolved_date = new Date(row.resolved_date);
                }
                return row;
              });
            return card;
          });
          response.data.inspection_conditions.sort((a, b) => a.preAt - b.preAt);
          setInspection(response.data);
        }
      });
  };
  if (!inspection) {
    return <div/>
  }
  const allFindings: FlatInspectionAssetFinding[] = []
  inspection.asset_data.forEach((asset) => {
    asset.findings.forEach(finding => {
      const obj = {...finding, ...asset};
      delete obj['findings'];
      allFindings.push(obj);
    });

  });
  const inpection_type = inspection.types.find(type => inspection.inspection.inspection_type === type.id);
  const findings: FlatInspectionAssetFinding[] = allFindings.filter(asset => asset.compliant === 2);
  switch (inpection_type && inpection_type.type || ""){
    case "hf":
      return <HFPreview inspection={inspection}/>;
    case "quick":
      return <QuickPreview inspection={inspection}/>;
    default:
      return <div/>
  }
}