import Rooter from "./Rooter";
import {AgenciesList} from "./Agencies/AgenciesList";
import {PlaygroundsList} from "./Agencies/PlaygroundsList";
import {InspectionsList} from "./Inspections/InspectionsList";
import {RouteComponentProps, Router} from "@reach/router";
import React from "react";
import {AssetsList} from "./Agencies/AssetsList";
import {AgenciesAddEdit} from "./Agencies/AgenciesAddEdit";
import {InspectionPreview} from "./Inspections/InspectionPreview";
import {GenericForm, GenericFormRow} from "../shared/GenericForm";
import {Playground} from "../models/PlaygroundData";
import {Asset} from "../models/AssetData";
import {UsersList} from "./Users/UsersList";
import {AddUserForm} from "./Users/AddUserForm";
import {Home} from "./Home/Home";

const playgroundRows: GenericFormRow<Playground>[] = [
  {type: 'string', fieldName: 'id', description: '', hidden: true},
  {type: 'string', fieldName: 'agency_id', description: '', hidden: true},
  {type: 'string', fieldName: 'name', description: ''},
  {type: 'string', fieldName: 'location_notes', description: ''},
  {type: 'number', fieldName: 'lat', description: ''},
  {type: 'number', fieldName: 'lon', description: ''},
];
const assetRows: GenericFormRow<Asset>[] = [
  {type: 'string', fieldName: 'id', description: '', hidden: true},
  {type: 'string', fieldName: 'playground_id', description: '', hidden: true},
  {type: 'string', fieldName: 'name', description: ''},
  // {type: 'string', fieldName: 'thumbnail_url', description: ''},
  {type: 'string', fieldName: 'notes', description: ''},
  {type: 'number', fieldName: 'part_number', description: ''},
  {type: 'number', fieldName: 'type', description: ''},
  // {type: 'date', fieldName: 'takenAt', description: ''},
];

export default function Routes() {
  return (
    <Router basepath={"/"}>
      <Rooter path="agencies">
        <AgenciesList path={"/"}/>
        <AgenciesAddEdit path={"add"}/>
        <AgenciesAddEdit path={"edit/:id"} id={":id"}/>
        <Rooter path={":agency_id"}>
          <Rooter path={"playgrounds"}>
            <PlaygroundsList path={"/"}/>
            <GenericForm path={"add"} rows={playgroundRows} navTo={`/agencies/$1/playgrounds`}
                         navToReplaceValues={[{find: "$1", replace: "agency_id"}]} postEndpoint={"/playground"}
                         formDataRouteParams={["agency_id"]}/>
            <GenericForm path={"edit/:id"} rows={playgroundRows} navTo={`/agencies/$1/playgrounds`}
                         navToReplaceValues={[{find: "$1", replace: "agency_id"}]} postEndpoint={"/playground"}
                         fetchEndPoint={"/playground"} fetchEndpointDataValues={[{find: "id", replace: "id"}]}
                         formDataRouteParams={["agency_id"]}/>
          </Rooter>
          <Rooter path={":playground_id"}>
            <Rooter path={"assets"}>
              <AssetsList path={"/"}/>
              <GenericForm path={"add"} rows={assetRows} navTo={`/agencies/$1/$2/assets`}
                           navToReplaceValues={[{find: "$2", replace: "playground_id"},{find: "$1", replace: "agency_id"}]}
                           postEndpoint={"/asset"}
                           formDataRouteParams={["playground_id"]}/>
              <GenericForm path={"edit/:id"} rows={assetRows} navTo={`/agencies/$1/$2/assets`}
                           navToReplaceValues={[{find: "$2", replace: "playground_id"},{find: "$1", replace: "agency_id"}]}
                           postEndpoint={"/asset"}
                           fetchEndPoint={"/asset"} fetchEndpointDataValues={[{find: "id", replace: "id"}]}
                           formDataRouteParams={["playground_id"]}/>
            </Rooter>
          </Rooter>
        </Rooter>
      </Rooter>
      <Rooter path={"inspections"}>
        <InspectionsList path="list"/>
        <InspectionsList path="archived" archived={true}/>
        <InspectionPreview path=":inspection_id/preview"/>
      </Rooter>
      <Rooter path={"users"}>
        <UsersList path="list"/>
        <AddUserForm path="add"/>
      </Rooter>
      <Home default/>
    </Router>
  )
}

const NotFound = (props: RouteComponentProps) => <p>Sorry, nothing here</p>