import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useStyles} from "../../theme/theme";
import {TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {deleteData, getData} from "../../shared/doLogin";
import UserContext from "../../contexts/UserContext";
import {AddCircle, ArtTrack, Delete, Edit, KeyboardArrowLeft} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {Link, RouteComponentProps} from "@reach/router";
import {ClientEvent} from "clientevent";
import {Events} from "../../models/Events";
import {Playground, PlaygroundListResponse} from "../../models/PlaygroundData";
import {ConfirmationDialog} from "../../shared/ConfirmationDialog";

interface Props extends RouteComponentProps {
  agency_id?: string;
}

export function PlaygroundsList(props: Props) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [playgrounds, setPlaygrounds] = useState<Playground[]>([]);
  const [selectedPlayground, setSelectedPlayground] = useState<string>("");
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    getPlayground();
  }, []);

  const getPlayground = () => {
    getData("/playgrounds", {agency_id: props.agency_id || ""}, user ? user.token : "")
      .then((response: PlaygroundListResponse) => {
        if (response.status === "Success") {
          setPlaygrounds(response.data.playgrounds);
        }
      });
  };

  const deletePlayground = (confirmation: boolean) => {
    if (!!confirmation)
      deleteData("/playground", {id: selectedPlayground}, user ? user.token : "")
        .then((response) => {
          if (response.status === "Success") {
            let newPlaygrounds = playgrounds.filter(p => p.id != selectedPlayground);
            setPlaygrounds(newPlaygrounds);
          }
          setConfirmDeleteDialogOpen(false);
        });
    setConfirmDeleteDialogOpen(false);
  };

  return (
    <TableContainer component={Paper}>
      <div style={{
        marginTop: 10,
        textAlign: "left",
        width: "100%",
      }}>
        <Link to={"../../"} style={{textDecoration: "none", fontSize: 20}}><KeyboardArrowLeft/>Go Back</Link>
        <Button
          style={{borderColor: "black", borderWidth: 1, borderRadius: 5, float: "right", marginRight: 20}}
          onClick={() => ClientEvent.emit(Events.NAVIGATE, "playgrounds/add")}
        >
          <AddCircle/>
          Add
        </Button>
      </div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Playground</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell align="right">Assets</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {playgrounds.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">{row.name}</TableCell>
              <TableCell>{row.location_notes}</TableCell>
              <TableCell align="right">{row.assets || 0}</TableCell>
              <TableCell align="right">
                <>
                  <Button onClick={() => ClientEvent.emit(Events.NAVIGATE, `playgrounds/edit/${row.id}`)}
                          title={"Edit"}
                  >
                    <Edit/>
                  </Button>
                  <Button onClick={() => {
                    setSelectedPlayground(row.id);
                    setConfirmDeleteDialogOpen(true);
                  }}
                          title={"delete"}
                  >
                    <Delete/>
                  </Button>
                  <Button onClick={() => ClientEvent.emit(Events.NAVIGATE, `${row.id}/assets`)}
                          title={"Web Preview"}
                  >
                    <ArtTrack/>
                  </Button>
                </>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmationDialog
        open={confirmDeleteDialogOpen}
        handleClose={(confirmation: boolean) => deletePlayground(confirmation)}
        confirmationDescription={"Are you sure you want to delete playground?"}
      />
    </TableContainer>
  )
}