import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import UserContext from "../../contexts/UserContext";
import 'react-datepicker/dist/react-datepicker.css';
import "../css/genericForm.css";
import {Button} from "@material-ui/core";
import {Filter, Tune} from "@material-ui/icons";
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import {FilterPicker} from "./FilterPicker";
import {getData} from "../doLogin";
import Badge from "@material-ui/core/Badge";

interface Props {
  filters: { [key: string]: string[] },
  updateFilters: (newFilters: { [key: string]: string[] }) => void,
  baseEndpoint: string,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: 500
    },
  }),
);

export function FilterModal(props: Props) {
  const user = useContext(UserContext);
  const [open, setOpen] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<any>({});
  const filterOptionsRef = useRef(filterOptions);
  const classes = useStyles();

  useEffect(() => {
    Object.keys(props.filters).forEach((option) => {
      fetchFilterOptions(option);
    });
  }, []);

  function getNumberOfFilters() {
    let count = 0;
    Object.values(props.filters).forEach(v => count = count + v.length);
    return count;
  }

  useEffect(() => {
    setFilterOptions({...filterOptionsRef.current});
  }, [filterOptionsRef.current]);

  const fetchFilterOptions = (field: string) => {
    getData(props.baseEndpoint + "/" + field, {}, user ? user.token : "")
      .then((response) => {
        if (response.status === "Success") {
          filterOptionsRef.current = {...filterOptionsRef.current, [field]: response.data};
        }
      });
  };

  return (
    <div>
      <Button onClick={() => {
        setOpen(!open)
      }}>
        <Badge badgeContent={getNumberOfFilters()} color="primary">
          <Tune/>
        </Badge>
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropComponent={Backdrop}
        className={classes.modal}
      >
        <div className={classes.paper}>
          <h2>Filter</h2>
          <div>
            {
              Object.keys(props.filters).map((field, index) => {
                return <FilterPicker options={filterOptions[field] || []} selected={props.filters[field] || []}
                                     updateSelectedOptions={(newSelected) => {
                                       props.updateFilters({...props.filters, [field]: newSelected})
                                     }} title={field} key={index}/>
              })
            }
          </div>
        </div>
      </Modal>
    </div>
  )
}