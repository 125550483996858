import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useStyles} from "../../theme/theme";
import {TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {deleteData, getData} from "../../shared/doLogin";
import UserContext from "../../contexts/UserContext";
import {AddCircle, Delete, Edit, KeyboardArrowLeft} from "@material-ui/icons";
import {Link, RouteComponentProps} from "@reach/router";
import {Asset, AssetListResponse} from "../../models/AssetData";
import {MediaImage} from "../../shared/MediaImage";
import {ClientEvent} from "clientevent";
import {Events} from "../../models/Events";
import Button from "@material-ui/core/Button";
import {ConfirmationDialog} from "../../shared/ConfirmationDialog";

interface Props extends RouteComponentProps {
  agency_id?: string;
  playground_id?: string;
}

export function AssetsList(props: Props) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<string>("");
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    getAssets();
  }, []);

  const getAssets = () => {
    getData("/assets", {playground_id: props.playground_id || ""}, user ? user.token : "")
      .then((response: AssetListResponse) => {
        if (response.status === "Success") {
          setAssets(response.data.assets);
        }
      });
  };

  const deleteAsset = (confirmation: boolean) => {
    if (!!confirmation)
      deleteData("/asset", {id: selectedAsset}, user ? user.token : "")
        .then((response) => {
          if (response.status === "Success") {
            let newAssets = assets.filter(a => a.id != selectedAsset);
            setAssets(newAssets);
          }
          setConfirmDeleteDialogOpen(false);
        });
    setConfirmDeleteDialogOpen(false);
  };

  return (
    <TableContainer component={Paper}>
      <div style={{
        marginTop: 10,
        textAlign: "left",
        width: "100%",
      }}>
        <Link to={"../../playgrounds"} style={{textDecoration: "none", fontSize: 20}}><KeyboardArrowLeft/>Go Back</Link>
        <Button
          style={{borderColor: "black", borderWidth:1, borderRadius: 5, float: "right", marginRight: 20}}
          onClick={() => ClientEvent.emit(Events.NAVIGATE, "assets/add")}
        >
          <AddCircle/>
          Add
        </Button>
      </div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell/>
            <TableCell>Asset</TableCell>
            <TableCell align="right">Part Number</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assets.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row" style={{width: 160}}>
                <MediaImage media_id={row.thumbnail_url}
                            width={300}
                            taken_at={row.takenAt}/>
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell align="right">{row.part_number}</TableCell>
              <TableCell align="right">{row.type}</TableCell>
              <TableCell align="right">
                <>
                  <Button onClick={() => ClientEvent.emit(Events.NAVIGATE, `assets/edit/${row.id}`)}
                          title={"Edit"}
                  >
                    <Edit/>
                  </Button>
                  <Button onClick={() => {
                    setSelectedAsset(row.id);
                    setConfirmDeleteDialogOpen(true);
                  }}
                          title={"delete"}
                  >
                    <Delete/>
                  </Button>
                </>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmationDialog
        open={confirmDeleteDialogOpen}
        handleClose={(confirmation: boolean) => deleteAsset(confirmation)}
        confirmationDescription={"Are you sure you want to delete asset?"}
      />
    </TableContainer>
  )
}