import * as React from "react";
import Button from "@material-ui/core/Button";
import 'react-datepicker/dist/react-datepicker.css';
import "./css/genericForm.css";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

interface Props {
  open: boolean,
  handleClose: (confirmation: boolean) => void,
  confirmationDescription?: string,
  confirmationButtonText?: string,
}

export function ConfirmationDialog(props: Props) {
  return (
    <Dialog
      data-testid={"confirmation-dialog"}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{cursor: 'move'}} data-testid={'confirm-dialog-title'} id="confirm-dialog-title">
        {props.confirmationDescription || "Confirm"}
      </DialogTitle>
      <DialogActions>
        <Button data-testid={"cancel-button"} autoFocus onClick={() => props.handleClose(false)} color="primary">
          Cancel
        </Button>
        <Button data-testid={"confirm-button"} onClick={() => props.handleClose(true)} color="primary">
          {props.confirmationButtonText || "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}