import {AssetCategory, AssetType, InspectionAssetData} from "../../models/InspectionData";

interface CategoryType {
  category: string;
  type: string;
}

export function getCategoryType(categories: AssetCategory[], types: AssetType[], value: number): CategoryType {
  const response: CategoryType = {
    category: "N/A",
    type: 'N/A'
  }
  // console.log(categories, types, value)
  const foundType = types.find(type => type.id === String(value));
  if (!foundType) return response;
  response.type = foundType.name;
  const foundCategory = categories.find(cat => cat.id === foundType.category_id);
  if (!foundCategory) return response;
  response.category = foundCategory.display;
  return response;
}

export function getAssetInfo(assets: InspectionAssetData[], assetId?: string | null): InspectionAssetData | null {
  if (!assetId || assetId === "") return null;
  let response = null;
  assets.forEach((asset) => {
    if (asset.assetId === assetId) {
      response = asset;
    }
  });
  return response;
}