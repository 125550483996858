import React, {useEffect, useState} from 'react';
import './App.css';
import Dashboard from "./Dashboard/Dashboard";
import LoginPage from "./Login/LoginPage";
import useCookie from "@devhammed/use-cookie/dist";
import UserContext from "./contexts/UserContext";
import {ClientEvent} from "clientevent";
import {Events} from "./models/Events";
import Loading from "./Login/Loading";
import {AuthStoreTokenData} from "./models/UserDetail";
import Logout from "./Login/Logout";
import {Router, navigate} from "@reach/router";
import {SnackBar} from "./shared/SnackBar";

export default function App() {
  const [token, setToken, deleteToken] = useCookie('token');
  const [userStateData, setUserStateData] = useState<AuthStoreTokenData | null>(null);

  useEffect(() => {
    ClientEvent.on(Events.SET_USER, "App", (data: AuthStoreTokenData) => {
      setUserStateData(data);
      //@ts-ignore
      setToken(data.token, {path: "/", sameSite: true});
    });
    ClientEvent.on(Events.DELETE_USER, "App", () => {
      console.log("delete");
      deleteToken();
      setUserStateData(null);
      setTimeout(() => ClientEvent.emit(Events.NAVIGATE, "/"), 10);
      setTimeout(() => document.location.reload(), 50);
    });
    ClientEvent.on(Events.NAVIGATE, "App", (data) => {
      navigate(data);
    });
    return () => {
      ClientEvent.off(Events.SET_USER, "App");
      ClientEvent.off(Events.SET_USER, "App");
      ClientEvent.off(Events.NAVIGATE, "App");
    }
  }, []);

  if (!!token && !userStateData) {
    return <div className="App">
      <header className="App-header">
        <Loading token={token}/>
      </header>
    </div>
  }

  return (
    <UserContext.Provider value={userStateData}>
      <div className="App">
        <Router>
          {!!userStateData ?
            <Dashboard path="*"/>
            :
            <LoginPage path="/*"/>
          }
          <Logout path={"/logout"}/>
        </Router>
        <SnackBar/>
      </div>
    </UserContext.Provider>
  );
}

