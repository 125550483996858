import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {getData, postData} from "../../shared/doLogin";
import UserContext from "../../contexts/UserContext";
import {Agency, AgencyListResponse, AgencyResponse} from "../../models/AgencyData";
import {RouteComponentProps} from "@reach/router";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import {ClientEvent} from "clientevent";
import {Events} from "../../models/Events";

interface Props extends RouteComponentProps {
  id?: string,
}

const newAgency: Agency = {
  id: "",
  name: "",
  poc: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
  notes: "",
};
const getNewAgency = () => {
  return {...newAgency}
};

export function AgenciesAddEdit(props: Props) {
  const user = useContext(UserContext);
  const [agency, setAgency] = useState<Agency>(getNewAgency());

  useEffect(() => {
    if(!!props.id) getData(`/agency`, {id: props.id || ""}).then((response: AgencyResponse) => {
      if(!!response) setAgency(response.data);
    });
  }, []);

  function updateAgency(field: keyof Agency, val: any) {
    setAgency({...agency, [field]: val});
  };

  const onSave = () => {
    postData("/agency", agency, user ? user.token : "")
      .then((response: AgencyListResponse) => {
        console.log("response: ", response);
        ClientEvent.emit(Events.NAVIGATE, "/agencies");
      });
  };
  const onCancel = () => {
    ClientEvent.emit(Events.NAVIGATE, "/agencies");
  };

  return (
    <TableContainer component={Paper}>
      <div style={{width: "50%", minWidth: 200, marginRight: "auto", marginLeft: "auto"}}>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">Name</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" value={agency.name}
                 onChange={(e) => updateAgency("name", e.target.value)}/>
          <FormHelperText id="my-helper-text">Name of the agency you wish to add</FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">POC</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" value={agency.poc}
                 onChange={(e) => updateAgency("poc", e.target.value)}/>
          <FormHelperText id="my-helper-text">Individual Name's of who to talk to</FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">Address 1</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text"  value={agency.address1}
                 onChange={(e) => updateAgency("address1", e.target.value)}/>
          <FormHelperText id="my-helper-text">Address of the agency</FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">Address 2</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" value={agency.address2}
                 onChange={(e) => updateAgency("address2", e.target.value)}/>
          <FormHelperText id="my-helper-text">Optional</FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">City</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text"  value={agency.city}
                 onChange={(e) => updateAgency("city", e.target.value)}/>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">State</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" value={agency.state}
                 onChange={(e) => updateAgency("state", e.target.value)}/>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">Zip</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text"  value={agency.zip}
                 onChange={(e) => updateAgency("zip", e.target.value)}/>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">Phone</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" value={agency.phone}
                 onChange={(e) => updateAgency("phone", e.target.value)}/>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">Email</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" value={agency.email}
                 onChange={(e) => updateAgency("email", e.target.value)}/>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">Notes</InputLabel>
          <Input multiline={true} rows={5} id="my-input" aria-describedby="my-helper-text" value={agency.notes}
                 onChange={(e) => updateAgency("notes", e.target.value)}/>
        </FormControl>

        <div style={{
          marginTop: 50,
          width: 300,
          display: "flex",
          justifyContent: "space-around",
          marginRight: "auto",
          marginLeft: "auto"
        }}>
          <Button color={"primary"} variant={"contained"} onClick={onSave}>Save</Button>
          <Button variant={"contained"} onClick={onCancel}>Cancel</Button>
        </div>

      </div>
    </TableContainer>
  )
}