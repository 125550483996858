import {formatDate, toDate} from "./utils";
import * as React from "react";
import {useState} from "react";

interface Props {
  media_id: string;
  taken_at: number;
  width?: number;
  height?: number;
  scaleWidth?: number;
  scaleHeight?: number;
  alignment?: "left" | "center" | "right"
}

export function MediaImage(props: Props) {
  const [height, setHeight] = useState<number>(props.height || 150);
  const [width, setWidth] = useState<number>(props.width || 150);
  if (!props.media_id) return <></>


  return (
    <div
      // style={{textAlign: "left"}}
    >
      <div style={{
        display: "block",
        margin: 5,
        // width: props.width || 150,
        textAlign: props.alignment || "left",
        alignContent: props.alignment || "left"
      }}>
        <img src={`/s1/media/${props.media_id}.jpg/preview`}
             width={!props.width ? "auto" : width}
             height={!props.height ? "auto" : height}
             onClick={() => window.open(`/s1/media/${props.media_id}.jpg`)}
             onMouseEnter={() => {
               if (props.scaleHeight && props.height) {
                 setHeight(props.scaleHeight)
               }
               if (props.scaleWidth && props.width) {
                 setWidth(props.scaleWidth)
               }
             }}
             onMouseLeave={() => {
               if (props.scaleHeight && props.height) {
                 setHeight(props.height)
               }
               if (props.scaleWidth && props.width) {
                 setWidth(props.width)
               }
             }}/>
      </div>
      {props.taken_at > 0 && (
        <span>{formatDate(toDate(props.taken_at), "")}</span>
      )}
    </div>
  );
}