import axios from 'axios';
import {ClientEvent, ClientEventTypes} from "clientevent";

// const API_URL = "http://localhost:8082/s1/";
const API_URL = "/s1/";
let tHeaders: TheHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

export function postData(url = '', data = {}, token = "") {
  if (token.length > 0) tHeaders['token'] = token;
  return axios({
    baseURL: API_URL,
    url: url,
    method: 'post',
    headers: tHeaders,
    responseType: 'json',
    data: data,
  }).then(response => response.data).catch(reason => {
    console.log(reason);
    ClientEvent.emit("GLOBAL_ERROR", reason);
    return false;
  });
}

export function putData(url = '', data = {}, token = "") {
  if (token.length > 0) tHeaders['token'] = token;
  return axios({
    baseURL: API_URL,
    url: url,
    method: 'put',
    headers: tHeaders,
    responseType: 'json',
    data: data,
  }).then(response => response.data).catch(reason => {
    console.log(reason);
    ClientEvent.emit("GLOBAL_ERROR", reason);
    return false;
  });
}

export function getData(url = "", data = {}, token = "") {
  if (token.length > 0) tHeaders['token'] = token;
  return axios({
    baseURL: API_URL,
    params: data,
    url: url,
    method: 'get',
    headers: tHeaders,
    responseType: 'json',
  }).then(response => response.data).catch(reason => {
    console.log(reason);
    ClientEvent.emit("GLOBAL_ERROR", reason);
    return false;
  });
}

export function deleteData(url = '', data = {}, token = "") {
  if (token.length > 0) tHeaders['token'] = token;
  return axios({
    baseURL: API_URL,
    url: url,
    method: 'delete',
    headers: tHeaders,
    responseType: 'json',
    data: data,
  }).then(response => response.data).catch(reason => {
    console.log(reason);
    ClientEvent.emit("GLOBAL_ERROR", reason);
    return false;
  });
}

export function uploadFile(url = "", data: any, token: string) {
  var lHeaders: TheHeaders = {
    'Content-Type': 'multipart/form-data',
  };
  if (token.length > 0) lHeaders['token'] = token;
  return axios({
    baseURL: API_URL,
    url: url,
    method: "POST",
    // cache: false,
    headers: lHeaders,
    responseType: 'json',
    data: data,
  }).then(response => response.data);
}

export function uploadFileRaw(url = "", data: any, token: string) {
  var lHeaders: TheHeaders = {
    'Content-Type': 'multipart/form-data',
  };
  if (token.length > 0) lHeaders['token'] = token;
  return axios({
    baseURL: API_URL,
    url: url,
    method: "POST",
    // cache: false,
    headers: lHeaders,
    responseType: 'json',
    data: data,
  });
}

interface TheHeaders {
  [key: string]: string;
}