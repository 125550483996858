import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useStyles} from "../../theme/theme";
import {TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {deleteData, putData} from "../../shared/doLogin";
import UserContext from "../../contexts/UserContext";
import {InspectionListResponse, InspectionListRow} from "../../models/InspectionData";
import {formatDate} from "../../shared/utils";
import {Archive, ArtTrack, Delete, Unarchive} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {RouteComponentProps} from "@reach/router";
import {ClientEvent} from "clientevent";
import {Events} from "../../models/Events";
import {ConfirmationDialog} from "../../shared/ConfirmationDialog";
import {FilterModal} from "../../shared/Filter/FilterModal";

interface Props extends RouteComponentProps {
  archived?: boolean;
}

export function InspectionsList(props: Props) {
  const classes = useStyles();
  const initFilters = {
    playground: [],
    type: [],
    performedBy: [],
    agency: []
  };
  const user = useContext(UserContext);
  const [inspections, setInspections] = useState<InspectionListRow[]>([]);
  const [selectedInspection, setSelectedInspection] = useState<string>("");
  const [confirmArchiveDialogOpen, setConfirmArchiveDialogOpen] = useState<boolean>(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>(initFilters);

  useEffect(() => {
    getFilteredInspections();
  }, [filters]);

  const getFilteredInspections = () => {
    putData("/inspection/filteredInspections", {archived: !!props.archived, filters: filters}, user ? user.token : "")
      .then((response: InspectionListResponse) => {
        if (response.status === "Success") {
          setInspections(response.data.inspections.map(row => {
            row.dated = new Date(row.dated);
            if (row.completed) {
              row.completed = new Date(row.completed);
            }
            return row;
          }));
        }
      });
  };

  const archiveInspection = (confirmation: boolean) => {
    if (!!confirmation)
      putData("/inspection/archive", {id: selectedInspection, archive: !props.archived}, user ? user.token : "")
        .then((response) => {
          if (response.status === "Success") {
            let newInspections = inspections.filter(i => i.id != selectedInspection);
            setInspections(newInspections);
          }
          setConfirmArchiveDialogOpen(false);
        });
    setConfirmArchiveDialogOpen(false);
  };

  const deleteInspection = (confirmation: boolean) => {
    if (!!confirmation)
      deleteData("/inspection", {id: selectedInspection}, user ? user.token : "")
        .then((response) => {
          if (response.status === "Success") {
            let newInspections = inspections.filter(a => a.id != selectedInspection);
            setInspections(newInspections);
          }
          setConfirmDeleteDialogOpen(false);
        });
    setConfirmDeleteDialogOpen(false);
  };

  const confirmationArchiveText = "Are you sure you want to archive the inspection?\n\n Once archived the inspection will no longer be editable nor with it be accessible from the inspections page.";
  const confirmationUnArchiveText = "Are you sure you want to un-archive the inspection?";

  return (
    <TableContainer component={Paper}>
      <div style={{
        marginTop: 10,
        textAlign: "right",
        marginRight: 30
      }}>
        <FilterModal baseEndpoint={"inspection/filters/type"} filters={filters}
                     updateFilters={(newFilters) => setFilters(newFilters)}/>
      </div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Playground</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Performed By</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inspections.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">{row.playgroundName}</TableCell>
              <TableCell align="right">{row.typeName}</TableCell>
              <TableCell align="right">{formatDate(row.dated)}</TableCell>
              <TableCell align="right">{!!row.completed ? "Complete" : "In progress"}</TableCell>
              <TableCell align="right">{row.inspected_by}</TableCell>
              <TableCell align="right">
                {(!!row.completed || 1 === 1) && (
                  <div>
                    <Button onClick={() => {
                      setSelectedInspection(row.id);
                      setConfirmDeleteDialogOpen(true);
                    }} title={"Delete"}
                    >
                      <Delete/>
                    </Button>
                    <Button onClick={() => {
                      setSelectedInspection(row.id);
                      setConfirmArchiveDialogOpen(true);
                    }} title={props.archived ? "Un-archive" : "Archive"}
                    >
                      {props.archived ? <Unarchive/> : <Archive/>}
                    </Button>
                    <Button onClick={() => ClientEvent.emit(Events.NAVIGATE, `/inspections/${row.id}/preview`)}
                            title={"Open"}>
                      <ArtTrack/>
                    </Button>
                  </div>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmationDialog
        open={confirmArchiveDialogOpen}
        handleClose={(confirmation: boolean) => archiveInspection(confirmation)}
        confirmationDescription={props.archived ? confirmationUnArchiveText : confirmationArchiveText}
      />
      <ConfirmationDialog
        open={confirmDeleteDialogOpen}
        handleClose={(confirmation: boolean) => deleteInspection(confirmation)}
        confirmationDescription={"Are you sure you want to delete inspection?"}
      />
    </TableContainer>
  )
}