import * as React from 'react';
import {SyntheticEvent, useEffect, useState} from 'react';
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import {Snackbar} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {ClientEvent, ClientEventTypes} from "clientevent";
import {Alert} from "@material-ui/lab";

interface Props {
  message?: string;
  onClose?: () => void;
}

export type SeverityLevel = "error" | "warning" | "info" | "success";

export function SnackBar(props: Props) {
  const {message, onClose} = props;
  const [open, setOpen] = useState(props.message && props.message !== '' ? true : false);
  const [messageText, setMessageText] = useState("");
  const [severity, setSeverity] = useState<SeverityLevel>("info");
  useEffect(() => {
    setOpen(props.message && props.message !== '' ? true : false);
    setMessageText(message || "");
  }, [message]);


  useEffect(() => {
    if (!message && !onClose) {
      ClientEvent.on(ClientEventTypes.SET_SNACK_BAR_MSG, "SnackbarPopup", (payload) =>
        displayMessage(payload));
      return () => {
        ClientEvent.off(ClientEventTypes.SET_SNACK_BAR_MSG, "SnackbarPopup");
      }
    }
  }, []);

  const displayMessage = (payload: { message: string, severity: SeverityLevel }) => {
    setMessageText(payload.message);
    setSeverity(payload.severity)
    setOpen(true);
  };

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
      setSeverity("info");
    }
  };

  return (
    <Snackbar
      style={{paddingBottom: "120px"}}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity || "info"}>
        {messageText}
      </Alert>

    </Snackbar>

  );

}