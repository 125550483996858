import React, {ReactElement, useState} from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ClientEvent, ClientEventTypes} from "clientevent";
import {AxiosPromise, AxiosResponse} from "axios";

interface Props {
  uploadFile: (file: File) => Promise<AxiosResponse>;
  dragAndDropText?: string;
  children?: ReactElement;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    onDrag: {
      width: '100%',
      height: '100%',
      border: "black dashed 2px",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
      backgroundPosition: 'center'
    },
    withChildren: {
      width: '100%',
      height: '100%',
    },
    noChildren: {
      width: 500,
      height: 200,
      backgroundColor: "lightsteelblue",
      border: "chocolate solid 1px",
      marginBottom: 10
    }
  }),
);

export function DragAndDropUploadZone(props: Props) {
  const [dragging, setDragging] = useState<number>(0);
  const classes = useStyles();

  const getClass = () => {
    if (props.children && dragging) return classes.onDrag;
    if (props.children) return classes.withChildren;
    return classes.noChildren;
  };

  const handleDroppedFile = async (e: React.DragEvent) => {
    setDragging(0);
    (e.currentTarget as HTMLElement).focus();

    const droppedFiles: any = [];

    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === 'file') {
          const file = e.dataTransfer.items[i].getAsFile();
          if (file) droppedFiles.push(file);
        }
      }
    }
    //TODO: do we need this logic?
    else {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        if (file)
          droppedFiles.push(file);
      }
    }

    e.preventDefault();
    await Promise.all(droppedFiles.map((file: File) => props.uploadFile(file)))
    // @ts-ignore
      .then((response: AxiosResponse[]) => {
        if (response[0].status === 200) {
          ClientEvent.emit(ClientEventTypes.SET_SNACK_BAR_MSG, {
            message: 'Successfully uploaded file!',
            severity: "success"
          });
        } else {
          ClientEvent.emit(ClientEventTypes.SET_SNACK_BAR_MSG, {
            message: 'There was an error uploading file!',
            severity: "error"
          });
        }
      }).catch(() => {
        ClientEvent.emit(ClientEventTypes.SET_SNACK_BAR_MSG, {
          message: 'There was an error uploading file!',
          severity: "error"
        });
      });
  };

  return (
    <div
      onDrop={handleDroppedFile}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragEnter={(e) => {
        setDragging((drag) => drag + 1);
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragLeave={() => {
        setDragging((drag) => drag - 1);
      }}
      data-testid={"drop-zone"}
      className={getClass()}
    >
      {props.children}
      {props.dragAndDropText || ""}
    </div>
  )
}
