import Logo from "../../assets/img/Logo.png";
import * as React from "react";

export function InspectionHeader() {
  return <>
    <div style={{
      marginTop: 40,
      width: 600,
      marginRight: "auto",
      marginLeft: "auto",
      display: "inline-block",
      fontSize: 14
    }}>
      <img src={Logo} alt={"Play-by-play Logo"}/>
      <div style={{marginRight: 20, display: "flex", width: 600, justifyContent: "space-evenly"}}>
        {/*<span>Phone</span>*/}
        <span>Arizona: 602.550.1332</span>
        <span>Nevada: 702.539.2820</span>
      </div>
      <div style={{marginRight: 20, display: "flex", width: 600, justifyContent: "space-evenly"}}>
        <span>info@playbyplayplaygrounds.com</span>
        <span>http://www.Playbyplaygrounds.com</span>
      </div>
    </div>
    <p style={{fontSize: 14, fontWeight: "bold", width: "80%", marginLeft: "auto", marginRight: "auto"}}>
      This report shows findings and tasks recorded during the inspection(s) matching the selection criteria set
      in the Report Wizard. If no findings or tasks are shown, none were recorded.
    </p>
    <p style={{fontSize: 14, fontWeight: "bold", width: "80%", marginLeft: "auto", marginRight: "auto"}}>
      Each inspection includes a declaration signed by the inspector stating that no defects were found other than
      those recorded.
    </p>
  </>
}