import React, {useEffect, useState} from 'react';
import {theme, useStyles} from '../theme/theme';
import {AppBar, IconButton, Toolbar, Typography} from "@material-ui/core";
import {
  AccountCircle, Archive,
  Assignment, AssignmentTurnedIn,
  Business,
  ChevronLeft,
  ChevronRight, EditAttributes, ExpandLess, ExpandMore,
  Home,
  Menu,
  More,
  PowerSettingsNew, Queue, StarBorder,
  SupervisorAccount
} from '@material-ui/icons';
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {ClientEvent} from "clientevent";
import {Events} from "../models/Events";
import {RouteComponentProps} from "@reach/router";
import Routes from "./Routes";
import Collapse from "@material-ui/core/Collapse";

export default function Dashboard(props: RouteComponentProps) {
  const classes = useStyles();
  // let history = useHistory();
  const [open, setOpen] = useState(false);
  const [inspectionsOpen, setInspectionsOpen] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);

  useEffect(() => {
    ClientEvent.on(Events.NAVIGATE, "Dashboard", (data) => {
      setTimeout(() => {
        setOpen(false);
      }, 100);
    });
    return () => {
      ClientEvent.off(Events.NAVIGATE, "Dashboard");
    }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const expandInspections = () => {
    setInspectionsOpen(!inspectionsOpen);
  };
  const expandAdmin = () => {
    setAdminOpen(!adminOpen);
  };

  // @ts-ignore
  return (
    <div className={classes.grow} style={{width: "100%"}}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            onClick={handleDrawerOpen}
            color="inherit"
            aria-label="open drawer"
          >
            <Menu/>
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Play By Play
          </Typography>
          {/*<div className={classes.search}>*/}
          {/*  <div className={classes.searchIcon}>*/}
          {/*    <Search/>*/}
          {/*  </div>*/}
          {/*  <InputBase*/}
          {/*    placeholder="Search…"*/}
          {/*    classes={{*/}
          {/*      root: classes.inputRoot,*/}
          {/*      input: classes.inputInput,*/}
          {/*    }}*/}
          {/*    inputProps={{'aria-label': 'search'}}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className={classes.grow}/>
          <div className={classes.sectionDesktop}>
            {/*<IconButton aria-label="show 4 new mails" color="inherit">*/}
            {/*  <Badge badgeContent={4} color="secondary">*/}
            {/*    <Mail/>*/}
            {/*  </Badge>*/}
            {/*</IconButton>*/}
            {/*<IconButton aria-label="show 17 new notifications" color="inherit">*/}
            {/*  <Badge badgeContent={17} color="secondary">*/}
            {/*    <Notifications/>*/}
            {/*  </Badge>*/}
            {/*</IconButton>*/}
            <IconButton
              edge="end"
              aria-label="account of current user"
              // aria-controls={menuId}
              aria-haspopup="true"
              // onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle/>
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              // aria-controls={mobileMenuId}
              aria-haspopup="true"
              // onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <More/>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft/> : <ChevronRight/>}
          </IconButton>
        </div>
        <Divider/>
        <List onClick={(data: any) => console.log(data)}>
          <ListItem button onClick={() => ClientEvent.emit(Events.NAVIGATE, "/")}
          >
            <ListItemIcon><Home/></ListItemIcon>
            <ListItemText primary={"Dashboard"}/>
          </ListItem>
          <ListItem button onClick={() => ClientEvent.emit(Events.NAVIGATE, "/agencies")}
          >
            <ListItemIcon><Business/></ListItemIcon>
            <ListItemText primary={"Agencies"}/>
          </ListItem>

          <ListItem button onClick={expandInspections}>
            <ListItemIcon>
              <ListItemIcon><Assignment/></ListItemIcon>
            </ListItemIcon>
            <ListItemText primary="Inspections"/>
            {inspectionsOpen ? <ExpandLess/> : <ExpandMore/>}
          </ListItem>
          <Collapse in={inspectionsOpen} timeout="auto" unmountOnExit addEndListener={() => {
          }}>
            <List component="div" style={{paddingLeft: 15}} disablePadding>
              <ListItem button onClick={() => ClientEvent.emit(Events.NAVIGATE, "/inspections/list")}
              >
                <ListItemIcon><AssignmentTurnedIn/></ListItemIcon>
                <ListItemText primary={"Active"}/>
              </ListItem>
              <ListItem button onClick={() => ClientEvent.emit(Events.NAVIGATE, "/inspections/archived")}
              >
                <ListItemIcon><Archive/></ListItemIcon>
                <ListItemText primary={"Archived"}/>
              </ListItem>
            </List>
          </Collapse>
          {/*<ListItem button onClick={expandAdmin}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <ListItemIcon><EditAttributes/></ListItemIcon>*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary="Admin"/>*/}
          {/*  {adminOpen ? <ExpandLess/> : <ExpandMore/>}*/}
          {/*</ListItem>*/}
          {/*<Collapse in={adminOpen} timeout="auto" unmountOnExit addEndListener={() => {*/}
          {/*}}>*/}
          {/*  <List style={{paddingLeft: 15}} component="div" disablePadding>*/}
          {/*    <ListItem button onClick={() => ClientEvent.emit(Events.NAVIGATE, "/users/list")}*/}
          {/*    >*/}
          {/*      <ListItemIcon><SupervisorAccount/></ListItemIcon>*/}
          {/*      <ListItemText primary={"Users"}/>*/}
          {/*    </ListItem>*/}
          {/*    <ListItem button onClick={() => ClientEvent.emit(Events.NAVIGATE, "/admin")}*/}
          {/*    >*/}
          {/*      <ListItemIcon><Queue/></ListItemIcon>*/}
          {/*      <ListItemText primary={"Inspection Options"}/>*/}
          {/*    </ListItem>*/}
          {/*  </List>*/}
          {/*</Collapse>*/}
        </List>
        <Divider/>
        <List>
          <ListItem button onClick={() => {
            ClientEvent.emit(Events.NAVIGATE, "/logout")
          }}>
            <ListItemIcon><PowerSettingsNew/></ListItemIcon>
            <ListItemText primary={"Logout"}/>
          </ListItem>
        </List>
      </Drawer>

      <Routes/>

    </div>
  );
}