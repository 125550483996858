import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {useStyles} from "../../../theme/theme";
import {TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import {
  FlatInspectionAssetFinding,
  InspectionAssetData, InspectionData, InspectionEditData,
  InspectionReportData,
  InspectionType,
  MxItemData
} from "../../../models/InspectionData";
import {formatDate, formatRiskLevel} from "../../../shared/utils";
import {getAssetInfo, getCategoryType} from "../utils";
import {useReactToPrint} from "react-to-print";
import {RouteComponentProps} from "@reach/router";
import Button from "@material-ui/core/Button";
import {ClientEvent} from "clientevent";
import {Events} from "../../../models/Events";
import {MediaImage} from "../../../shared/MediaImage";
import {InlineEdit} from "../../../shared/InlineEdit";
import {putData} from "../../../shared/doLogin";
import UserContext from "../../../contexts/UserContext";
import {InspectionHeader} from "../Header";
import "./preview.css";

interface Props extends RouteComponentProps {
  inspection: InspectionReportData;
}

export function HFPreview(props: Props) {
  const classes = useStyles();
  const user = useContext(UserContext);

  // const {inspection} = props;
  const previewRef = useRef(null);

  const [inspection, setInspection] = useState<InspectionReportData>(props.inspection);
  const [findings, setFindings] = useState<FlatInspectionAssetFinding[]>([]);
  const [inspection_type, setInspection_type] = useState<InspectionType | undefined>(undefined);
  const [showPencils, setShowPencils] = useState<boolean>(true);

  useEffect(() => {
    buildFindings(props.inspection);
  }, []);

  // @ts-ignore
  const handlePrint = useReactToPrint({
    content: () => previewRef.current,
  });

  if (!inspection) {
    return <div/>
  }

  function buildFindings(newInspection: InspectionReportData) {
    const allFindings: FlatInspectionAssetFinding[] = []
    newInspection.asset_data.forEach((asset) => {
      asset.findings.forEach(finding => {
        const obj = {...finding, ...asset};
        delete obj['findings'];
        allFindings.push(obj);
      });
    });
    const inpection_type = newInspection.types.find(type => newInspection.inspection.inspection_type === type.id);
    const findings: FlatInspectionAssetFinding[] = allFindings.filter(asset => asset.compliant === 2 || (asset.notes && asset.notes.length > 0));
    setFindings(findings);
    setInspection_type(inpection_type);
  }

  function updateInspectionMxItem(newItem: MxItemData) {
    let newMxItems = inspection.mx_items.map(item => {
      if (item.id === newItem.id) return {...newItem};
      return item;
    });
    setInspection({...inspection, mx_items: newMxItems});
    delete newItem.media;
    putData("/inspection/mx_item", newItem, user ? user.token : "")
      .then((response: any) => {
        console.log("response: ", response);
      });
  };

  function updateInspectionData(newInspection: InspectionEditData) {
    let newInspectionData: InspectionData = {
      ...inspection.inspection,
      upper_notes: newInspection.upper_notes
    }

    putData("/inspection", newInspection, user ? user.token : "")
      .then((response: any) => {
        console.log("response: ", response);
        setInspection({...inspection, inspection: newInspectionData})
      });
  }

  function updateInspectionFinding(newFinding: FlatInspectionAssetFinding) {
    let newAssetData = inspection.asset_data.map(asset => {
      if (newFinding.assetId == asset.assetId) {
        let newFindings = asset.findings.map(finding => {
          if (finding.id == newFinding.id) return newFinding;
          return finding;
        });
        return {...asset, findings: newFindings};
      }
      return asset;
    });
    let newInspection = {...inspection};
    newInspection.asset_data = newAssetData;
    setInspection({...newInspection});
    buildFindings(newInspection);
    putData("/inspection/finding", convertFlatAssetFindingToFindingAsset(newFinding), user ? user.token : "")
      .then((response: any) => {
        console.log("response: ", response);
      });
  };

  function convertFlatAssetFindingToFindingAsset(newFinding: FlatInspectionAssetFinding) {
    let convertedAssetFinding = {...newFinding};
    delete convertedAssetFinding.asset_notes;
    delete convertedAssetFinding.assetId;
    delete convertedAssetFinding.media;
    delete convertedAssetFinding.name;
    delete convertedAssetFinding.part_number;
    delete convertedAssetFinding.type;
    delete convertedAssetFinding.thumbnail_url;
    delete convertedAssetFinding.takenAt;
    delete convertedAssetFinding.playground_id;
    delete convertedAssetFinding.discovered_date;
    delete convertedAssetFinding.resolve_by_date;
    delete convertedAssetFinding.resolved_date;

    return convertedAssetFinding;
  }


  return (
    <>
      <div style={{position: "absolute", marginTop: 10}}>
        <Button variant="contained"
                onClick={() => ClientEvent.emit(Events.NAVIGATE, `/inspections/list`)}
        >&lt; Go back</Button>
        <Button variant="contained"
                onClick={() => {
                  setShowPencils(false);
                  setTimeout(() => handlePrint && handlePrint(), 500)
                }}
        >Print this out</Button>
      </div>
      <TableContainer component={Paper} ref={previewRef} style={{textAlign: "center"}}>
        <InspectionHeader/>
        <InlineEdit enableEdit={showPencils} width={500} justifyContent={"center"} value={inspection.inspection.upper_notes || ""}
                    confirm={(newVal: string) => {
                      updateInspectionData({id: inspection.inspection.inspection_id, upper_notes: newVal});
                    }}/>
        <h4>Inspection of:</h4>
        <Table style={{width: "80%", marginLeft: "auto", marginRight: "auto"}} aria-label="simple table dense"
               size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Site Name</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="right">Performed By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{inspection.inspection.playground_name}</TableCell>
              <TableCell align="left">{formatDate(inspection.inspection.inspection_dated)}</TableCell>
              <TableCell align="left">{inspection_type && inspection_type.description || ""}</TableCell>
              <TableCell align="right">{inspection.inspection.inspected_by}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {findings.length > 0 && (
          <div>
            <h4>Findings Summary:</h4>
            <Table style={{width: "80%", marginLeft: "auto", marginRight: "auto"}} aria-label="simple table"
                   size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Asset Name</TableCell>
                  <TableCell align="left">Finding Notes</TableCell>
                  <TableCell align="left">Finding Date</TableCell>
                  <TableCell align="left">Resolve By Date</TableCell>
                  <TableCell align="right">Risk Level</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {findings.map((asset, index) => {
                  return <TableRow key={index}>
                    <TableCell align="left">{asset.name}</TableCell>
                    {/*HERE*/}
                    <TableCell align="left">
                      <InlineEdit enableEdit={showPencils} value={asset.notes || ""} confirm={(newVal: string) => {
                        updateInspectionFinding({...asset, notes: newVal});
                      }}/>
                    </TableCell>
                    <TableCell
                      align="left">{formatDate(asset.discovered_date || inspection.inspection.inspection_dated, "N/A")}</TableCell>
                    <TableCell align="left">{formatDate(asset.resolve_by_date, "N/A")}</TableCell>
                    <TableCell align="right">{formatRiskLevel(asset.risk_level)}</TableCell>
                  </TableRow>
                })}

              </TableBody>
            </Table>
          </div>
        )}

        <div className={"sectionBeforeAuto"}>
          <h4>Inspected Playground Assets:</h4>
          <Table style={{width: "80%", marginLeft: "auto", marginRight: "auto"}} aria-label="simple table"
                 size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Asset</TableCell>
                <TableCell align="right">Category</TableCell>
                <TableCell align="right">Type</TableCell>
                {/*<TableCell align="left">Part Number</TableCell>*/}
              </TableRow>
            </TableHead>
            <TableBody>
              {inspection.asset_data.map((asset, index) => {
                const cat = getCategoryType(inspection.asset_categories, inspection.asset_types, asset.type);
                return <TableRow key={index}>
                  <TableCell align="left">
                    <strong>{asset.name}</strong><br/>
                    <MediaImage media_id={asset.thumbnail_url}
                                taken_at={asset.takenAt}
                                height={150}
                                scaleHeight={300}
                    />
                  </TableCell>
                  <TableCell align="right">{cat.category || ""}</TableCell>
                  <TableCell align="right">{cat.type || ""}</TableCell>
                  {/*<TableCell align="left">{asset.part_number || ""}</TableCell>*/}
                </TableRow>
              })}

            </TableBody>
          </Table>
        </div>

        {findings.length > 0 && (
          <div className={"sectionBeforeAlways"}>
            <h4>{inspection.inspection.playground_name} Findings:</h4>
            {findings.map((finding, index) => {
              return <Table key={index} style={{width: "60%", marginLeft: "auto", marginRight: "auto"}}
                            aria-label="simple table" size={"small"}>
                <TableBody>
                  <TableRow style={{pageBreakInside: "avoid", pageBreakAfter: "auto"}}>
                    <TableCell colSpan={8}>
                      <>
                        <div style={{display: "flex"}}>
                      <span style={{width: "50%"}}>
                        <strong>Asset:</strong>
                        {finding.name}
                        <br/>
                        {/*<strong>Finding Note:</strong>*/}
                        {/*{finding.notes}*/}
                        <div style={{display: "flex", alignItems: 'center'}}>
                          <strong>Finding Note:</strong>
                          <InlineEdit enableEdit={showPencils} value={finding.notes} confirm={(newVal: string) => {
                            updateInspectionFinding({...finding, notes: newVal});
                          }}/>
                        </div>
                        </span>
                          <span style={{width: "50%"}}>
                        <strong>Discovered:</strong>
                            {formatDate(finding.discovered_date)}
                            <br/>
                        <strong>Risk Level:</strong>
                            {formatRiskLevel(finding.risk_level)}
                      </span>
                        </div>
                        <div style={{display: "flex"}}>
                          {finding.media.map((media, index) => {
                            return (
                              <div key={index} style={{display: "block", margin: 5, width: 150, textAlign: "center"}}>
                                <MediaImage media_id={media.media_id}
                                            taken_at={media.takenAt}
                                            height={150}
                                            scaleHeight={300}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </>
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            })}
          </div>
        )}
        {inspection.mx_items.length > 0 && (
          <div className={"sectionBeforeAuto"}>
            <h4>{inspection.inspection.playground_name} Maintenance Tasks:</h4>
            {inspection.mx_items.map((item: MxItemData, index) => {
              const assetInfo: InspectionAssetData | null = getAssetInfo(inspection.asset_data, item.asset_id);
              return <Table key={index} style={{width: "60%", marginLeft: "auto", marginRight: "auto"}}
                            aria-label="simple table" size={"small"}>
                <TableBody>
                  <TableRow style={{pageBreakInside: "avoid", pageBreakAfter: "auto"}}>
                    <TableCell colSpan={8}>
                      <>
                        <div>
                          {assetInfo && (
                            <>
                              <strong>Asset:</strong> {assetInfo.name}
                              <br/>
                            </>
                          )}
                          {item.title && item.title.length > 0 && ((assetInfo && assetInfo.name !== item.title) || !assetInfo) && (
                            // <>
                            <div style={{display: "flex", alignItems: 'center'}}>
                              <strong>Title:</strong>
                              <InlineEdit enableEdit={showPencils} value={item.title} confirm={(newVal: string) => {
                                updateInspectionMxItem({...item, title: newVal});
                              }}/>
                              <br/>
                              {/*<strong>Title:</strong> {item.title}*/}
                            </div>
                            // </>
                          )}
                          <div style={{display: "flex", alignItems: 'center'}}>
                            <strong>Note:</strong>
                            <InlineEdit enableEdit={showPencils} value={item.notes} confirm={(newVal: string) => {
                              updateInspectionMxItem({...item, notes: newVal});
                            }}/>
                          </div>
                          {/*{item.notes}*/}
                        </div>
                        <div style={{display: "flex"}}>
                          {item.media.map((media, index) => {
                            return (
                              <div key={index} style={{display: "block", margin: 5, textAlign: "center"}}>
                                <MediaImage media_id={media.media_id}
                                            taken_at={media.takenAt}
                                            height={150}
                                            scaleHeight={300}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </>
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            })}
          </div>
        )}

        {inspection.inspection_conditions.length > 0 && (
          <div className={"sectionBeforeAuto"}>
            <h4>Arrival / Departure Conditions:</h4>
            <Table style={{width: "80%", marginLeft: "auto", marginRight: "auto"}} aria-label="simple table"
                   size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Arrival</TableCell>
                  <TableCell align="center">Departure</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inspection.inspection_conditions.map((row, index) => {
                  return <TableRow key={index} style={{pageBreakInside: "avoid", pageBreakAfter: "auto"}}>
                    <TableCell align="center" style={{textAlign: "center"}}>
                      <MediaImage media_id={row.pre}
                                  taken_at={row.preAt}
                                  height={200}
                                  scaleHeight={300}
                                  alignment={"center"}
                      />
                    </TableCell>
                    <TableCell align="center" style={{textAlign: "center"}}>
                      <MediaImage media_id={row.post}
                                  taken_at={row.postAt}
                                  height={200}
                                  scaleHeight={300}
                                  alignment={"center"}
                      />
                    </TableCell>
                  </TableRow>
                })}

              </TableBody>
            </Table>
          </div>
        )}

      </TableContainer>
    </>
  )
}