import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useStyles} from "../../theme/theme";
import {TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {deleteData, getData, putData} from "../../shared/doLogin";
import UserContext from "../../contexts/UserContext";
import {InspectionListResponse, InspectionListRow, UserListResponse, UserListRow} from "../../models/InspectionData";
import {formatDate} from "../../shared/utils";
import {AddCircle, Archive, ArtTrack, Delete, Unarchive} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {RouteComponentProps} from "@reach/router";
import {ClientEvent} from "clientevent";
import {Events} from "../../models/Events";
import {ConfirmationDialog} from "../../shared/ConfirmationDialog";
import {FilterModal} from "../../shared/Filter/FilterModal";

interface Props extends RouteComponentProps {
}

export function UsersList(props: Props) {
  const classes = useStyles();

  const user = useContext(UserContext);
  const [users, setUsers] = useState<UserListRow[]>([]);
  const getFilteredInspections = () => {
    getData("/users/list", {}, user ? user.token : "")
      .then((response: UserListResponse) => {
        if (response.status === "Success") setUsers(response.data.users);
      });
  };

  useEffect(()=>{
    getFilteredInspections();
  }, []);

  return (
    <TableContainer component={Paper}>
      <div style={{
        marginTop: 10,
        textAlign: "right",
        marginRight: 30
      }}>
        <Button
          style={{borderColor: "black", borderWidth: 1, borderRadius: 5}}
          onClick={() => ClientEvent.emit(Events.NAVIGATE, "add")}
        >
          <AddCircle/>
          Add
        </Button>
      </div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">User Name</TableCell>
            <TableCell align="right">First Name</TableCell>
            <TableCell align="right">Last Name</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">{row.username}</TableCell>
              <TableCell align="right">{row.FName}</TableCell>
              <TableCell align="right">{row.LName}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}