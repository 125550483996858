import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {getData, postData} from "../../shared/doLogin";
import UserContext from "../../contexts/UserContext";
import {Agency, AgencyListResponse, AgencyResponse} from "../../models/AgencyData";
import {RouteComponentProps} from "@reach/router";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import {ClientEvent, ClientEventTypes} from "clientevent";
import {Events} from "../../models/Events";
import {AdminPermissions, UserDetail} from "../../models/UserDetail";
import {NewUser} from "../../../../api/models/DBModels";

interface Props extends RouteComponentProps {
  id?: string,
}

const newUser: NewUser = {
  id: "",
  username: "",
  organization: "",
  FName: "",
  LName: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  title: "",
  email: "",
  phone: "",
  perms: {},
  password: "",
  confirmPassword: "",
};
const getNewUser = () => {
  return {...newUser}
};


export function AddUserForm(props: Props) {
  const user = useContext(UserContext);
  const [newUser, setNewUser] = useState<NewUser>(getNewUser());

  function updateUser(field: keyof NewUser, val: any) {
    setNewUser({...newUser, [field]: val});
  };

  const onSave = () => {
    if (newUser.password.length <= 0 || newUser.username.length <= 0 || newUser.confirmPassword.length <= 0 || newUser.FName.length <= 0 || newUser.LName.length <= 0) {
      ClientEvent.emit(ClientEventTypes.SET_SNACK_BAR_MSG, {
        message: "please fill out all fields before submitting",
        severity: "error"
      });
    } else if (newUser.password !== newUser.confirmPassword) {
      ClientEvent.emit(ClientEventTypes.SET_SNACK_BAR_MSG, {
        message: "password and confirm password do not match!",
        severity: "error"
      });
    } else {
      postData("/users", newUser, user ? user.token : "")
        .then((response) => {
          console.log("response: ", response);
          if (response && response.status === "Error")
            ClientEvent.emit(ClientEventTypes.SET_SNACK_BAR_MSG, {
              message: response.message,
              severity: "error"
            });
          else
            ClientEvent.emit(Events.NAVIGATE, "/users/list");
        });
    }
  };
  const onCancel = () => {
    ClientEvent.emit(Events.NAVIGATE, "/users/list");
  };

  return (
    <TableContainer component={Paper}>
      <div style={{width: "50%", minWidth: 200, marginRight: "auto", marginLeft: "auto"}}>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">First Name</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" value={newUser.FName}
                 onChange={(e) => updateUser("FName", e.target.value)}/>
          <FormHelperText id="my-helper-text"></FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">Last Name</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" value={newUser.LName}
                 onChange={(e) => updateUser("LName", e.target.value)}/>
          <FormHelperText id="my-helper-text"></FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">User Name</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" value={newUser.username}
                 onChange={(e) => updateUser("username", e.target.value)}/>
          <FormHelperText id="my-helper-text"></FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">Password</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" value={newUser.password} type="password"
                 onChange={(e) => updateUser("password", e.target.value)}/>
          <FormHelperText id="my-helper-text"></FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">Confirm Password</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" value={newUser.confirmPassword} type="password"
                 onChange={(e) => updateUser("confirmPassword", e.target.value)}/>
          <FormHelperText id="my-helper-text"></FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">Email</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" value={newUser.email}
                 onChange={(e) => updateUser("email", e.target.value)}/>
          <FormHelperText id="my-helper-text"></FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="my-input">Phone Number</InputLabel>
          <Input id="my-input" aria-describedby="my-helper-text" value={newUser.phone}
                 onChange={(e) => updateUser("phone", e.target.value)}/>
          <FormHelperText id="my-helper-text"></FormHelperText>
        </FormControl>

        <div style={{
          marginTop: 50,
          width: 300,
          display: "flex",
          justifyContent: "space-around",
          marginRight: "auto",
          marginLeft: "auto"
        }}>
          <Button color={"primary"} variant={"contained"} onClick={onSave}>Save</Button>
          <Button variant={"contained"} onClick={onCancel}>Cancel</Button>
        </div>

      </div>
    </TableContainer>
  )
}